
declare var google: any;

import Vue from "vue";

export default Vue.extend({
  mounted() {
    if (this.$route.query.redirect != null) {
      localStorage.setItem("redirect", this.$route.query.redirect.toString());
    } else {
      localStorage.removeItem("redirect");
    }

    google.accounts.id.initialize({
      client_id:
        "241040949707-5ei39mgb3f1esg22el8flgsi7v1s6m5g.apps.googleusercontent.com",
      auto_select: true,
      callback: async (res: any) => {
        await this.$store.dispatch("login", {
          id_token: res.credential,
        });

        const redirect = localStorage.getItem("redirect");

        if (redirect != null) {
          await this.$router.push(redirect);
        } else {
          await this.$router.push("/");
        }
      },
    });

    google.accounts.id.renderButton(
      document.getElementById("google_login"),
      { theme: "outline", size: "large" } // customization attributes
    );
  },
});
